import { useTracking } from '@vp/react-tracking';
import React, { useState } from 'react';
import { Button, FlexBox, Icon, Spinner } from '@vp/swan';
import { __ } from '@99designs/i18n';
import {
    TRACKING_PAGE_MY_ACCOUNT,
    useActionContext,
    useCollaborationContext,
} from '../../../context';
import { useEditAction } from '../../../hooks/useEditAction';
import { useOrderAction } from '../../../hooks/useOrderAction';
import { useWorkEntity } from '../../../hooks/useWorkEntity';
import { DesignLiveModal } from '../DesignLiveModal/DesignLiveModal';
import { ProductPreview } from '../ProductPreview';

export interface TopbarActionsProps {
    designLiveExperimentVariant: string | null;
}

export const TopbarActions: React.FC<TopbarActionsProps> = ({ designLiveExperimentVariant }) => {
    const [loadingOrder, setLoadingOrder] = useState(false);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const { tracking } = useTracking();
    const { locale, trackingProperties, collaborationType } = useCollaborationContext();
    const {
        actionOptions: { edit, approve },
    } = useActionContext();
    const editUrl = useEditAction();
    const { workRevisionId } = useWorkEntity();
    const { onClickOrder } = useOrderAction();
    const [isDesignLiveModalOpen, setIsDesignLiveModalOpen] = useState(false);

    return (
        <FlexBox gap="between-actions" flexWrap="wrap" justifyContent="flex-end">
            {workRevisionId && (
                <>
                    <Button
                        skin="tertiary"
                        onClick={async () => {
                            tracking.track('Collaboration Action Taken', {
                                type: 'Viewed preview',
                                collaborationType,
                                ...trackingProperties,
                            });
                            setIsPreviewOpen(true);
                        }}
                        iconPosition="left"
                    >
                        <Icon iconType="preview" />
                        {__('Preview')}
                    </Button>
                    {isPreviewOpen && (
                        <ProductPreview
                            locale={locale}
                            workRevisionId={workRevisionId}
                            setIsPreviewOpen={setIsPreviewOpen}
                        />
                    )}
                </>
            )}
            {edit !== 'hidden' && editUrl && (
                <Button
                    skin="tertiary"
                    disabled={edit === 'disabled'}
                    onClick={async () => {
                        tracking.track('Collaboration Action Taken', {
                            type: 'Edit yourself',
                            collaborationType,
                            ...trackingProperties,
                        });
                        window.location.href = editUrl;
                    }}
                    iconPosition="left"
                >
                    <Icon iconType="edit" />
                    {__('Edit yourself')}
                </Button>
            )}
            {designLiveExperimentVariant &&
                designLiveExperimentVariant !== 'control' &&
                collaborationType === 'Standard' && (
                    <>
                        <Button
                            skin="tertiary"
                            onClick={async () => {
                                tracking.track('Experiment Clicked', {
                                    ...trackingProperties,
                                    label: 'DesignLive fake door test topbar CTA clicked',
                                    experimentName: 'design_live_during_collaboration_demand_test',
                                    variationName: designLiveExperimentVariant,
                                    pageName: `Design Services:Collaboration:Activity:${trackingProperties.collaborationStatus}:${collaborationType}`,
                                    pageSection: TRACKING_PAGE_MY_ACCOUNT,
                                    pageStage: TRACKING_PAGE_MY_ACCOUNT,
                                });
                                setIsDesignLiveModalOpen(true);
                            }}
                            iconPosition="left"
                        >
                            <Icon iconType="videoChat" />
                            {__('Schedule a video call')}
                        </Button>
                        {isDesignLiveModalOpen && (
                            <DesignLiveModal
                                experimentVariant={designLiveExperimentVariant}
                                setIsDesignLiveModalOpen={setIsDesignLiveModalOpen}
                            />
                        )}
                    </>
                )}
            {approve !== 'hidden' && (
                <Button
                    skin="primary"
                    disabled={approve === 'disabled'}
                    onClick={async () => {
                        setLoadingOrder(true);
                        await onClickOrder();
                    }}
                    compactMode
                >
                    {loadingOrder && <Spinner accessibleText={__('Loading...')} />}
                    {__('Approve & order')}
                </Button>
            )}
        </FlexBox>
    );
};
