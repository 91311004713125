import { __, __vistaRoute } from '@99designs/i18n';
import { useCollaborationContext } from '../context';

export function useMenuProps() {
    const { mpvId } = useCollaborationContext();

    return {
        menuButtonLabel: __('Menu'),
        label: titleCase(mpvId),
        links: [
            {
                label: __('My Design Services'),
                path: __vistaRoute('/graphic-design/dashboard'),
                iconType: 'myProjects' as const,
            },
            { label: __('My Account'), path: '/my-account', iconType: 'account' as const },
            {
                label: __('Start a new project'),
                path: '/graphic-design/brief/printed-product',
                iconType: 'plus' as const,
            },
            {
                label: __('Help centre'),
                path: __vistaRoute('/customer-care/help-center/'),
                iconType: 'helpQuery' as const,
            },
        ],
    };
}

function titleCase(str: string) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (s) => s.toUpperCase());
}
