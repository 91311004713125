import { useTracking } from '@vp/react-tracking';
import { Dispatch, SetStateAction, useState } from 'react';
import {
    Button,
    Column,
    GridContainer,
    Li,
    ModalDialog,
    ModalDialogBody,
    ModalDialogButtons,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    Row,
    Typography,
    Ul,
} from '@vp/swan';
import { TRACKING_PAGE_MY_ACCOUNT, useCollaborationContext } from '../../../context';
import modalHeaderImg from './modal-header.png';

export interface DesignLiveModalProps {
    experimentVariant: string;
    setIsDesignLiveModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const DesignLiveModal: React.FC<DesignLiveModalProps> = ({
    experimentVariant,
    setIsDesignLiveModalOpen,
}) => {
    const [showConfirmCopy, setShowConfirmCopy] = useState(false);
    const { tracking } = useTracking();
    const { trackingProperties, collaborationType } = useCollaborationContext();
    return (
        <ModalDialog isOpen={true} onRequestDismiss={() => setIsDesignLiveModalOpen(false)}>
            {!showConfirmCopy && (
                <ModalDialogContent>
                    <ModalDialogNav>
                        <ModalDialogCloseButton accessibleText={'Close'} />
                    </ModalDialogNav>
                    <ModalDialogBody>
                        <Typography fontSkin="title-headline" marginBottom="3">
                            Schedule a live video call
                        </Typography>
                        <Typography fontSkin="title-section" marginBottom="3">
                            {getPriceTextFromVariant(experimentVariant)}
                        </Typography>
                        <Typography marginBottom="3">
                            Schedule a 30 minute video call with a designer.
                        </Typography>
                        <Typography marginBottom="3">
                            Once you've selected a time slot, we'll email you a Zoom meeting link.
                        </Typography>
                        <Typography marginBottom="3">
                            During your call, you'll get to see your design and provide feedback so
                            changes can be made in real time.
                        </Typography>
                        <Ul skin="checkmark">
                            <Li>Work with your designer on a live call.</Li>
                            <Li>Review your design in real time.</Li>
                            <Li>
                                Get designer support to choose print options and submit design for
                                printing.
                            </Li>
                            <Li>Fast turnaround. </Li>
                        </Ul>
                    </ModalDialogBody>
                    <ModalDialogFooter>
                        <ModalDialogButtons>
                            <GridContainer>
                                <Row>
                                    <Column span={6}>
                                        <Button
                                            width="full-width"
                                            skin="secondary"
                                            onClick={() => setIsDesignLiveModalOpen(false)}
                                        >
                                            Cancel
                                        </Button>
                                    </Column>
                                    <Column span={6}>
                                        <Button
                                            width="full-width"
                                            skin="primary"
                                            onClick={() => {
                                                setShowConfirmCopy(true);
                                                tracking.track('Experiment Clicked', {
                                                    ...trackingProperties,
                                                    label: 'DesignLive fake door test modal CTA clicked',
                                                    experimentName:
                                                        'design_live_during_collaboration_demand_test',
                                                    variationName: experimentVariant,
                                                    pageName: `Design Services:Collaboration:Activity:${trackingProperties.collaborationStatus}:${collaborationType}`,
                                                    pageSection: TRACKING_PAGE_MY_ACCOUNT,
                                                    pageStage: TRACKING_PAGE_MY_ACCOUNT,
                                                });
                                            }}
                                        >
                                            {getButtonTextFromVariant(experimentVariant)}
                                        </Button>
                                    </Column>
                                </Row>
                            </GridContainer>
                        </ModalDialogButtons>
                    </ModalDialogFooter>
                </ModalDialogContent>
            )}
            {showConfirmCopy && (
                <ModalDialogContent>
                    <ModalDialogNav>
                        <ModalDialogCloseButton accessibleText={'Close'} />
                    </ModalDialogNav>
                    <ModalDialogHeader>
                        <ModalDialogTitle>
                            There currently isn't enough availability.
                        </ModalDialogTitle>
                    </ModalDialogHeader>
                    <ModalDialogBody>
                        This is a new feature we are testing, so we currently don't have enough
                        designers available to schedule your video call.
                    </ModalDialogBody>
                    <ModalDialogFooter>
                        <ModalDialogButtons>
                            <Button
                                width="full-width"
                                skin="secondary"
                                onClick={() => setIsDesignLiveModalOpen(false)}
                            >
                                Cancel
                            </Button>
                        </ModalDialogButtons>
                    </ModalDialogFooter>
                </ModalDialogContent>
            )}
        </ModalDialog>
    );
};

function getPriceTextFromVariant(variant: string) {
    switch (variant) {
        case 'test_1':
            return 'Free';
        case 'test_2':
            return '$19';
        case 'test_3':
            return '$29';
        default:
            return 'Free';
    }
}

function getButtonTextFromVariant(variant: string) {
    switch (variant) {
        case 'test_1':
            return 'Schedule a time';
        case 'test_2':
        case 'test_3':
            return 'Pay & schedule a time';
        default:
            return 'Schedule a free call';
    }
}
