import BugsnagPluginReact from '@bugsnag/plugin-react';
import TrackingJsModule from '@vp/react-tracking';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import { getAllExperiments, initialize, waitTillAvailable } from '@vp/ab-reader';
import { ScreenClassProvider } from '@vp/swan';
import { COLLABORATE_BASE_PATH, SwanConfiguration } from '@99designs/collaborate-views';
import { AlertProvider, Brief } from '@99designs/collaborate-views';
import { useSetupCtcLocally } from '@99designs/design-services-common';
import {
    IdentityProvider,
    RouteWithAuth,
    buildAuthConfiguration,
    redirectToInternalLogin,
    useIdentityContext,
} from '@99designs/design-services-common';
import { VistaExperiment, VistaExperimentsProvider } from '@99designs/experiments';
import { AuthenticatedApolloProvider } from '@99designs/graph-utils';
import { __, getCurrentCulture } from '@99designs/i18n';
import { FixedCanvas } from '../FixedCanvas';
import i18n from '../lib/i18n';
import { Activity } from '../routes/activity/Activity';
import { Appointment } from '../routes/appointment/Appointment';
import { BriefNavigator } from '../routes/brief-navigator/BriefNavigator';
import { CollaborateLayout } from '../routes/collaborate/Collaborate';
import { NotFoundPage } from '../routes/error/NotFound';
import { Feedback } from '../routes/feedback/Feedback';
import { GetStarted } from '../routes/get-started/GetStarted';
import { Messaging } from '../routes/messaging/Messaging';
import { Revision } from '../routes/revision/Revision';

/**
 * The below import structure is a workaround for the fact that these packages are not built as ES Modules.
 * At dev time the import is correctly importing just the default export, however at build time the import is
 * importing the entire module. We therefor have to use the default key to get the default export.
 * https://github.com/vitejs/vite/issues/4209
 */
const TrackingJs = (TrackingJsModule as any).default ?? TrackingJsModule; // eslint-disable-line @typescript-eslint/no-explicit-any

Bugsnag.start({
    apiKey: process.env.VITE_COLLABORATE_BUGSNAG_API_KEY ?? '',
    enabledReleaseStages: ['production'],
    releaseStage: process.env.VITE_COLLABORATE_BUGSNAG_RELEASE_STAGE,
    autoTrackSessions: process.env.VITE_COLLABORATE_BUGSNAG_RELEASE_STAGE === 'production',
    plugins: [new BugsnagPluginReact(React)],
    onError: (event) => {
        event.addMetadata('request', {
            url: event.request.url,
            headers: event.request.headers,
        });

        event.addMetadata('debug info', {
            errorLocation: typeof window === 'undefined' ? 'server' : 'client',
        });
    },
});
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary();

export function App() {
    const currentCulture = useMemo(() => getCurrentCulture(), []);
    const env = process.env.VITE_COLLABORATE_TRACKING_ENV;
    const authConfig = useMemo(() => {
        return buildAuthConfiguration(
            process.env.VITE_COLLABORATE_HOSTNAME || '',
            {
                internalAccess: 'Hxjf7fHzMahYoVNsWkFYlN79aVIsGTQK',
                directAccess: '3f4309c1ac4ff306397cc2424fa2ef40',
            },
            /^https?:\/\/(.+\.es\.vpsvc\.com)/
        );
    }, []);
    i18n.changeLanguage(currentCulture);
    const [vistaExperiments, setVistaExperiments] = useState<VistaExperiment[]>([]);

    useEffect(() => {
        initialize();

        (async () => {
            const available = await waitTillAvailable(5000);
            if (available) {
                const fetchedExperiments = getAllExperiments();
                setVistaExperiments(fetchedExperiments);
            }
        })();
    }, []);

    useSetupCtcLocally(process.env.VITE_BRIEFS_BUGSNAG_RELEASE_STAGE !== 'production');

    return (
        <AlertProvider>
            <ErrorBoundary>
                <SwanConfiguration>
                    <ScreenClassProvider>
                        <IdentityProvider locale={currentCulture} {...authConfig}>
                            <TrackingJs
                                tenant="vistaprint"
                                culture={currentCulture}
                                environment={env}
                            />
                            <RouteWithAuth>
                                <BrowserRouter>
                                    <AuthenticatedApolloProvider
                                        locale={currentCulture}
                                        endpoint={
                                            process.env.VITE_COLLABORATE_GRAPHQL_ENDPOINT || ''
                                        }
                                    >
                                        <VistaExperimentsProvider value={vistaExperiments}>
                                            <AppRoutes />
                                        </VistaExperimentsProvider>
                                    </AuthenticatedApolloProvider>
                                </BrowserRouter>
                            </RouteWithAuth>
                        </IdentityProvider>
                    </ScreenClassProvider>
                </SwanConfiguration>
            </ErrorBoundary>
        </AlertProvider>
    );
}

const AppRoutes = () => (
    <Routes>
        <Route path={COLLABORATE_BASE_PATH}>
            <Route path={'brief'} element={<BriefNavigator />} />
            <Route path={`brief/:careforceAltRequestId`} element={<BriefNavigator />} />

            <Route path={`:collaborationId`} element={<CollaborateLayout />}>
                <Route index element={<GetStarted />} />
                <Route path="delivery/:deliveryVersionId" element={<FixedCanvas />}>
                    <Route index element={<Activity />} />
                    <Route path="activity" element={<Activity />} />
                    <Route path="brief" element={<Brief />} />
                    <Route path="appointment" element={<Appointment />} />
                    <Route path="messaging" element={<Messaging />} />
                </Route>
                <Route path="delivery/:deliveryVersionId/revision" element={<Revision />} />
            </Route>

            <Route
                path="feedback/:collaborationId/delivery/:deliveryVersionId"
                element={<Feedback />}
            />
            {/* Log in helper routes */}
            <Route path="log-in-care" element={<LogInCare />} />
            <Route path="log-in-customer" element={<LogInCustomer />} />

            <Route path="*" element={<NotFoundPage />} />
        </Route>
        {/* As the base path to this app on vista.com that redirects to this app is vista.com/collaboration we cannot have routes outside this base path */}
    </Routes>
);

const LogInCare = () => {
    const { SignOut } = useIdentityContext();
    SignOut();
    redirectToInternalLogin();
    return <>Logging in to VP CARE console client...</>;
};

const LogInCustomer = () => {
    const { SignOut } = useIdentityContext();
    SignOut();
    window.location.href = '/';
    return <>Logging in via customer flow...</>;
};

export default App;
