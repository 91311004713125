import { useEffect, useState } from 'react';
import { useCreateChatClient } from 'stream-chat-react';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import { useIdentityContext } from '@99designs/design-services-common';

export const useInitialiseChatClient = (token: string) => {
    const { shopperId } = useIdentityContext();
    const apiKey = process.env.VITE_COLLABORATE_STREAM_API_KEY ?? '';

    const [chatClient, setChatClient] = useState<StreamChat<DefaultGenerics> | null>(null);
    const [loading, setLoading] = useState(true);

    const client = useCreateChatClient({
        apiKey,
        tokenOrProvider: token,
        userData: { id: shopperId },
    });

    useEffect(() => {
        // useCreateChatClient takes time to initialise, hence storing the client to our state means we have more control with the timing of the initalisation and making sure subsequent code (like Stream's UI components) are ready to use the client
        if (client) {
            setChatClient(client);
            setLoading(false);
        }
    }, [client]);

    return { chatClient, loading };
};
