export const getCollaborationType = ({
    hasDesignLiveDelivery,
    isFreelance,
    isUploadAndGo,
}: {
    hasDesignLiveDelivery: boolean;
    isFreelance: boolean;
    isUploadAndGo: boolean;
}): string => {
    switch (true) {
        case hasDesignLiveDelivery:
            return 'Design Live';
        case isFreelance:
            return 'Freelance';
        case isUploadAndGo:
            return 'Upload and Go';
        default:
            return 'Standard';
    }
};
