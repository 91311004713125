import { useMemo } from 'react';
import { NotFound, TRACKING_PAGE_MY_ACCOUNT } from '@99designs/collaborate-views';
import { PageLayout } from '@99designs/design-services-layouts';
import { getCurrentCulture } from '@99designs/i18n';
import { PageTracking } from '@99designs/tracking';

const pageTracking: PageTracking = {
    pageName: 'Collaborate:Not Found',
    pageSection: TRACKING_PAGE_MY_ACCOUNT,
    pageStage: TRACKING_PAGE_MY_ACCOUNT,
    pageDept: 'Design Services',
};

// Used as catch-all route for unmatched routes in Collaborate app
export const NotFoundPage: React.FC = () => {
    const locale = useMemo(() => getCurrentCulture(), []);

    return (
        <PageLayout locale={locale} breadcrumbs={[]} pageTracking={pageTracking}>
            <NotFound />
        </PageLayout>
    );
};
