import { Navigate, useLocation, useParams } from 'react-router-dom';
import { COLLABORATE_BASE_PATH, ErrorLoadingState } from '@99designs/collaborate-views';
import { useCareforceBriefUrlQuery } from '@99designs/collaborate-views';

type BriefNavigatorParams = {
    careforceAltRequestId: string;
};

export const BriefNavigator = () => {
    const location = useLocation();

    // Getting the requestAltId from query param if present
    const params = new URLSearchParams(location.search);
    const requestAltId = params.get('requestAltId');

    const { careforceAltRequestId } = useParams<BriefNavigatorParams>() as BriefNavigatorParams;

    const requestId = careforceAltRequestId || requestAltId || '';

    const { data, loading, error } = useCareforceBriefUrlQuery({
        variables: {
            careforceAltRequestId: requestId,
        },
    });

    return (
        <ErrorLoadingState
            loading={loading}
            data={data}
            errors={error}
            errorContext="failed to determine brief url"
        >
            {(data) => {
                if (data.careforceBriefUrl.url.startsWith(COLLABORATE_BASE_PATH)) {
                    return <Navigate to={data.careforceBriefUrl.url} />;
                } else {
                    window.location.assign(data.careforceBriefUrl.url);
                }
            }}
        </ErrorLoadingState>
    );
};
