import React from 'react';
import { AlertBox, BoundedContent } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { useCollaborationContext } from '../../context';
import { isDevEnv } from '../../util/isDevEnv';
import { ChatWrapper } from './ChatWrapper';

export const MessagingPanel: React.FC = () => {
    const { collaborationId, isFreelance } = useCollaborationContext();

    if (!isFreelance || !isDevEnv) {
        return (
            <BoundedContent padding="5">
                <AlertBox skin="error">Messaging is not available for this project.</AlertBox>
            </BoundedContent>
        );
    }

    return <ChatWrapper collaborationPublicId={collaborationId} />;
};
