import i18n, { Resource } from 'i18next';
import { urlTranslations } from '@99designs/i18n';
import dadk from '../../translations/da-DK.json';
import dede from '../../translations/de-DE.json';
import engb from '../../translations/en-GB.json';
import enus from '../../translations/en-US.json';
import eses from '../../translations/es-ES.json';
import esus from '../../translations/es-US.json';
import fifi from '../../translations/fi-FI.json';
import frca from '../../translations/fr-CA.json';
import frfr from '../../translations/fr-FR.json';
import itit from '../../translations/it-IT.json';
import nbno from '../../translations/nb-NO.json';
import nlnl from '../../translations/nl-NL.json';
import ptpt from '../../translations/pt-PT.json';
import svse from '../../translations/sv-SE.json';

const resources: Resource = {
    'da-dk': {
        translation: {
            ...dadk,
            ...urlTranslations.dadkURLs,
        },
    },
    'de-at': {
        translation: {
            ...urlTranslations.deatURLs,
        },
    },
    'de-ch': {
        translation: {
            ...urlTranslations.dechURLs,
        },
    },
    'de-de': {
        translation: {
            ...dede,
            ...urlTranslations.dedeURLs,
        },
    },
    'en-ca': {
        translation: {
            ...urlTranslations.encaURLs,
        },
    },
    'en-au': {
        translation: {
            ...urlTranslations.enauURLs,
        },
    },
    'en-gb': {
        translation: {
            ...engb,
            ...urlTranslations.engbURLs,
        },
    },
    'en-ie': {
        translation: {
            ...urlTranslations.enieURLs,
        },
    },
    'en-in': {
        translation: {
            ...urlTranslations.eninURLs,
        },
    },
    'en-nz': {
        translation: {
            ...urlTranslations.ennzURLs,
        },
    },
    'en-sg': {
        translation: {
            ...urlTranslations.ensgURLs,
        },
    },
    'en-us': {
        translation: {
            ...urlTranslations.enusURLs,
            ...enus,
        },
    },
    'es-es': {
        translation: {
            ...eses,
            ...urlTranslations.esesURLs,
        },
    },
    'es-us': {
        translation: {
            ...esus,
            ...urlTranslations.esusURLs,
        },
    },
    'fi-fi': {
        translation: {
            ...fifi,
            ...urlTranslations.fifiURLs,
        },
    },
    'fr-be': {
        translation: {
            ...urlTranslations.frbeURLs,
        },
    },
    'fr-ca': {
        translation: {
            ...frca,
            ...urlTranslations.frcaURLs,
        },
    },
    'fr-ch': {
        translation: {
            ...urlTranslations.frchURLs,
        },
    },
    'fr-fr': {
        translation: {
            ...frfr,
            ...urlTranslations.frfrURLs,
        },
    },
    'it-ch': {
        translation: {
            ...urlTranslations.itchURLs,
        },
    },
    'it-it': {
        translation: {
            ...itit,
            ...urlTranslations.ititURLs,
        },
    },
    'nb-no': {
        translation: {
            ...nbno,
            ...urlTranslations.nbnoURLs,
        },
    },
    'nl-be': {
        translation: {
            ...urlTranslations.nlbeURLs,
        },
    },
    'nl-nl': {
        translation: {
            ...nlnl,
            ...urlTranslations.nlnlURLs,
        },
    },
    'pt-pt': {
        translation: {
            ...ptpt,
            ...urlTranslations.ptptURLs,
        },
    },
    'sv-se': {
        translation: {
            ...svse,
            ...urlTranslations.svseURLs,
        },
    },
};

// const enableDebug = !!import.meta.env['ENABLE_TRANSLATION_DEBUG'] || false;
const fallbackLanguages = {
    'en-ca': ['en-au', 'en-gb', 'en-us'],
    'en-nz': ['en-au', 'en-gb', 'en-us'],
    'en-au': ['en-gb', 'en-us'],
    'en-ie': ['en-gb', 'en-us'],
    'en-in': ['en-gb', 'en-us'],
    'en-sg': ['en-gb', 'en-us'],
    'en-gb': ['en-us'],
    'fr-be': ['fr-fr', 'en-us'],
    'fr-ca': ['fr-fr', 'en-us'],
    'fr-ch': ['fr-fr', 'en-us'],
    'es-us': ['es-es', 'en-us'],
    'nl-be': ['nl-nl', 'en-us'],
    'de-at': ['de-de', 'en-us'],
    'de-ch': ['de-de', 'en-us'],
    'it-ch': ['it-it', 'en-us'],
    default: ['en-us'],
};

i18n.init({
    debug: false,
    resources,
    fallbackLng: fallbackLanguages,
    lowerCaseLng: true, //locales are set to lower case e.g. "en-us" not "en-US"
    interpolation: {
        escapeValue: false, // React already provides escaping
    },
    nsSeparator: '*****', // namespace selector is a string delimited which defaults to ":". Overriding to *** so urlTranslations.that urls don't get split
    returnObjects: true,
});

export default i18n;
