import { Helmet } from 'react-helmet-async';
import { Column, GridContainer, Row, Typography } from '@vp/swan';
import {
    FieldDisplayWrapper,
    ProductCard,
    ReadOnlyReview,
} from '@99designs/feature-design-services-brief';
import { __ } from '@99designs/i18n';
import { BriefFragment, useCollaborationContext } from '../../context';
import { BriefLayout } from './BriefLayout';

export const Brief = () => {
    return (
        <>
            <Helmet>
                <title>{__('Vistaprint | Design Services Project Brief')}</title>
            </Helmet>
            <BriefLayout>{(data) => <BriefUI {...data} />}</BriefLayout>
        </>
    );
};

/**
 * Brief UI component
 * This is a placeholder used for testing the brief data fetching
 */
export const BriefUI = ({
    brief,
    collaborationId,
}: {
    brief: BriefFragment;
    collaborationId: string;
}) => {
    const collab = useCollaborationContext();
    const printProductOptions = collab.printProduct.productOptionsJson
        ? (JSON.parse(collab.printProduct.productOptionsJson) as Record<string, string>)
        : undefined;
    const productType = collab.mpvId
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\b\w/g, (c) => c.toUpperCase());
    return (
        <GridContainer mt={9} mb={9}>
            <Row>
                <Column span={12} px={'5'}>
                    <Typography fontSkin="title-section" px={'5'} mb={'7'}>
                        {__('Your design project details')}
                    </Typography>
                    {collaborationId && (
                        <FieldDisplayWrapper skin="standard" title={__('Request ID')}>
                            <Typography>{collaborationId}</Typography>
                        </FieldDisplayWrapper>
                    )}
                    {printProductOptions && productType && (
                        <FieldDisplayWrapper skin="standard" title={__('Selected product options')}>
                            <Typography>Product: {productType}</Typography>
                            {Object.entries(printProductOptions).map(([key, value]) => (
                                <Typography key={key}>
                                    {key}: {value}
                                </Typography>
                            ))}
                        </FieldDisplayWrapper>
                    )}
                    {(brief.product || brief.request?.expertService) && (
                        <ProductCard
                            product={brief.product ?? undefined}
                            expertService={brief.request?.expertService ?? undefined}
                            hideImage
                            withDesignerEarnings={
                                collab.isFreelance && collab.viewerRole !== 'VIEWER_ROLE_CLIENT'
                            }
                            fullWidth
                            removeBottomMargin
                        />
                    )}
                    {brief.workName && (
                        <FieldDisplayWrapper
                            skin="standard"
                            title={__("The template you've started with")}
                        >
                            <Typography fontSize="small">{brief.workName}</Typography>
                        </FieldDisplayWrapper>
                    )}
                    <ReadOnlyReview isCollaborationMode={true} brief={brief} />
                </Column>
            </Row>
        </GridContainer>
    );
};
