import { useMemo } from 'react';
import { Box, FlexBox, Typography, tokens } from '@vp/swan';
import { getCurrentCulture } from '@99designs/i18n';

export interface CalendarBadgeProps {
    dateTime: string;
    cancelled?: boolean;
}

export const CalendarBadge: React.FC<CalendarBadgeProps> = ({ dateTime, cancelled }) => {
    const currentCulture = useMemo(() => getCurrentCulture(), []);

    const date = new Date(dateTime).toLocaleDateString(currentCulture, {
        day: 'numeric',
        month: 'short',
    });

    const time = new Date(dateTime).toLocaleTimeString(currentCulture, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    });

    //Align time with Vista's 'human time' docs
    const humanTime = time.replace(/^0/, '').replace('a.m.', 'AM').replace('p.m.', 'PM');

    const [day, month] = date.split(' ');

    // styles
    const textColor = cancelled ? tokens.SwanSemColorTextDisabled : tokens.SwanSemColorTextStandard;
    const textDecoration = cancelled ? 'line-through' : 'none';
    const backgroundColor = cancelled
        ? tokens.SwanSemColorBgDisabled
        : tokens.SwanSemColorBgStandard;

    return (
        <Box height={'80px'}>
            <FlexBox
                flexDirection={'column'}
                alignItems={'center'}
                style={{
                    overflow: 'hidden',
                    borderRadius: tokens.SwanSemBorderRadiusStandard,
                    border: `1px solid ${tokens.SwanBaseColorGrey300}`,
                    minWidth: '75px',
                }}
            >
                <FlexBox
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingX={3}
                    paddingY={3}
                    style={{ width: '100%', backgroundColor: tokens.SwanSemColorBgStandard }}
                >
                    <Typography
                        fontSkin={'body-small-bold'}
                        style={{ textDecoration, color: textColor }}
                    >
                        {month}
                    </Typography>
                    <Typography
                        fontSkin={'title-section'}
                        style={{ textDecoration, color: textColor }}
                    >
                        {day}
                    </Typography>
                </FlexBox>
                <FlexBox
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingX={3}
                    paddingY={2}
                    style={{ width: '100%', backgroundColor }}
                    darkMode
                >
                    <Typography
                        fontSkin={'footnote-bold'}
                        style={{ textDecoration, color: textColor }}
                    >
                        {humanTime}
                    </Typography>
                </FlexBox>
            </FlexBox>
        </Box>
    );
};
