import { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import Bugsnag from '@bugsnag/js';
import { Spinner } from '@vp/swan';
import styled from 'styled-components';
import { PageLayout } from '@99designs/design-services-layouts';
import { __, getCurrentCulture } from '@99designs/i18n';
import { PageTracking } from '@99designs/tracking';
import { TRACKING_PAGE_MY_ACCOUNT } from '../../context';
import { InternalServer } from '../lib/Errors/InternalServer';
import { NotFound } from '../lib/Errors/NotFound';

const FullPage = styled.div`
    height: 100vh;
    width: 100vw;
`;

const CenteredContent = styled.div`
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const getPageTracking = (pageName: string): PageTracking => ({
    pageSection: TRACKING_PAGE_MY_ACCOUNT,
    pageStage: TRACKING_PAGE_MY_ACCOUNT,
    pageDept: 'Design Services',
    pageName,
});

enum GraphQLErrorTypes {
    NOT_FOUND = 'NOT_FOUND',
    PERMISSION_DENIED = 'PERMISSION_DENIED',
}

/**
 * Full page wrapper for the loading and error states of high-level components that use Apollo client.
 * This function takes the result of a query and renders a function with a non nullable
 * version of data when the loading is done.
 * If there are errors, it will render a full page error in the Collaboration app.
 */
export function ErrorLoadingFullPage<T>({
    loading,
    loadingAccessibleText = __('Loading'),
    error,
    errorContext,
    data,
    children,
}: {
    loading: boolean;
    loadingAccessibleText?: string;
    errorContext?: string; // if an error occurs, this string will be passed to bugsnag as additional context
    error: ApolloError | undefined;
    data: T | undefined;
    children: (data: T) => JSX.Element | undefined;
}) {
    const locale = useMemo(() => getCurrentCulture(), []);

    if (loading) {
        return (
            <FullPage>
                <CenteredContent>
                    <Spinner accessibleText={loadingAccessibleText} showText />
                </CenteredContent>
            </FullPage>
        );
    }

    if (error) {
        const errorType = getErrorType(error);

        Bugsnag.notify(error, (event) => {
            event.context = errorContext;
        });

        if (
            errorType === GraphQLErrorTypes.NOT_FOUND ||
            errorType === GraphQLErrorTypes.PERMISSION_DENIED
        ) {
            return (
                <PageLayout
                    pageTracking={getPageTracking('Collaboration: Not found')}
                    breadcrumbs={[]}
                    locale={locale}
                >
                    <NotFound />
                </PageLayout>
            );
        }

        return (
            <PageLayout
                pageTracking={getPageTracking('Collaboration: Internal server error')}
                breadcrumbs={[]}
                locale={locale}
            >
                <InternalServer />
            </PageLayout>
        );
    }

    return data ? <>{children(data)}</> : null;
}

function getErrorType(error: ApolloError | undefined): string | null {
    let errorType: string | null = null;

    if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
        const graphQLError = error.graphQLErrors[0];
        if (graphQLError.extensions && graphQLError.extensions.type) {
            errorType = graphQLError.extensions.type;
        }
    }

    return errorType;
}
