import { useTracking } from '@vp/react-tracking';
import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { fireImpression } from '@vp/ab-reader';
import {
    ActionOptions,
    ActionProvider,
    CollaborationProvider,
    ErrorLoadingFullPage,
    Shell,
    TopbarActions,
    getCollaborationStatus,
    useCollaborationContext,
    useGetCollaborationQuery,
    useMenuProps,
    useNavigationLinks,
} from '@99designs/collaborate-views';
import { DrawerPortalProvider, SwanModeProvider } from '@99designs/design-services-layouts';
import { useGetVistaExperimentVariant } from '@99designs/experiments';
import { __ } from '@99designs/i18n';

type CollaborateParams = {
    collaborationId: string;
};

export function CollaborateLayout() {
    const { collaborationId } = useParams<CollaborateParams>() as CollaborateParams;
    const { data, error, loading } = useGetCollaborationQuery({
        variables: {
            collaborationPublicId: collaborationId,
        },
    });

    return (
        <ErrorLoadingFullPage loading={loading} error={error} data={data}>
            {(data) => (
                <CollaborationProvider
                    collaboration={data.getCollaboration.collaboration}
                    collaborationId={collaborationId}
                    brief={data.getCollaboration.brief}
                >
                    <SwanModeProvider value={{ isCompactMode: true }}>
                        <DrawerPortalProvider>
                            <Collaborate />
                        </DrawerPortalProvider>
                    </SwanModeProvider>
                </CollaborationProvider>
            )}
        </ErrorLoadingFullPage>
    );
}

export function Collaborate() {
    const { permissions } = useCollaborationContext();
    const [overriddenActionOptions, setOverriddenActionOptions] = useState<ActionOptions | null>(
        null
    );

    return (
        <ActionProvider
            permissions={permissions}
            overriddenActionOptions={overriddenActionOptions}
            setOverriddenActionOptions={setOverriddenActionOptions}
        >
            <CollaborateShell />
        </ActionProvider>
    );
}

export function CollaborateShell() {
    const collaborateData = useCollaborationContext();
    const navigationLinks = useNavigationLinks();
    const menuProps = useMenuProps();
    const { tracking } = useTracking();
    const collaborationStatus = getCollaborationStatus(collaborateData.state);

    const designLiveExperimentVariant = useGetVistaExperimentVariant(
        'design_live_during_collaboration_demand_test'
    );
    useEffect(() => {
        if (designLiveExperimentVariant && collaborateData.collaborationType === 'Standard') {
            fireImpression(
                'design_live_during_collaboration_demand_test',
                designLiveExperimentVariant
            );
        }
    }, [designLiveExperimentVariant, collaborateData.collaborationType]);

    return (
        <Shell
            topbarProps={{
                status: collaborationStatus,
                navUrl: '/graphic-design/dashboard',
                navLabel: __('My Design Services'),
                onLinkMenuChange: () =>
                    tracking.track('Navigation Clicked', collaborateData.trackingProperties),
                menuProps,
                children: TopbarActions({ designLiveExperimentVariant }),
            }}
            sidebarProps={{ links: navigationLinks }}
            bottomBarProps={{
                bottomBarLinks: navigationLinks,
                onMenuLinkClick: () =>
                    tracking.track('Navigation Clicked', collaborateData.trackingProperties),
            }}
        >
            <Outlet />
        </Shell>
    );
}
