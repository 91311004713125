import { AlertBox, BoundedContent, FlexBox, Spinner, tokens } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { StreamChatClient } from './StreamChatClient';
import { useFetchChatAndToken } from './useFetchChatAndToken';

export const ChatWrapper = ({ collaborationPublicId }: { collaborationPublicId: string }) => {
    const { token, loading } = useFetchChatAndToken(collaborationPublicId);

    if (loading)
        return (
            <BoundedContent padding={'5'}>
                <Spinner accessibleText={__('Loading...')} />
            </BoundedContent>
        );

    if (!token)
        return (
            <BoundedContent padding="5">
                <AlertBox toast skin="error">
                    {__('Sorry, there has been an error. Please refresh or try again later.')}
                </AlertBox>
            </BoundedContent>
        );

    return (
        <FlexBox
            flexDirection="column"
            alignItems="center"
            style={{ height: '100%', padding: tokens.SwanSemSpace5 }}
        >
            <FlexBox
                flexDirection="column-reverse"
                style={{ overflow: 'auto', width: '100%', height: '100%' }}
            >
                <StreamChatClient collaborationPublicId={collaborationPublicId} token={token} />
            </FlexBox>
        </FlexBox>
    );
};
