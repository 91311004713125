import { createContext, useCallback, useContext } from 'react';
import { fireImpression } from '@vp/ab-reader';
import { VistaExperiment } from '../types/VistaExperiment';

const VistaExperimentsContext = createContext<VistaExperiment[]>([]);

export const VistaExperimentsProvider = VistaExperimentsContext.Provider;
export const VistaExperimentsConsumer = VistaExperimentsContext.Consumer;

/**
 * @deprecated This hook is deprecated in favor of the useInVistaExperimentVariant hook. It will be removed once it is no longer in use by active experiments
 */
export function useGetVistaExperimentVariant(experimentKey: string): string | null {
    const experiments: VistaExperiment[] = useContext(VistaExperimentsContext);
    if (!experiments || experiments.length === 0) {
        return null;
    }
    const experiment = experiments.find((exp) => exp.experimentKey === experimentKey);
    if (!experiment) {
        console.warn(`Experiment with key ${experimentKey} not found`);
        return null;
    }
    return experiment.variationKey;
}

export function useInVistaExperimentVariant(experimentKey: string) {
    const experiments: VistaExperiment[] = useContext(VistaExperimentsContext);
    return useCallback(
        (variant: string) => {
            const experiment = experiments?.find((exp) => exp.experimentKey === experimentKey);
            if (!experiment) {
                console.warn(`Experiment with key ${experimentKey} not found`);
                return;
            }
            if (typeof window !== 'undefined') {
                fireImpression(experiment.experimentKey, experiment.variationKey);
            }
            return experiment.variationKey === variant;
        },
        [experiments, experimentKey]
    );
}
