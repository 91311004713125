import {
    Channel,
    ChannelHeader,
    Chat,
    MessageInput,
    MessageList,
    Thread,
    Window,
} from 'stream-chat-react';
import { BoundedContent, Spinner } from '@vp/swan';
import { __ } from '@99designs/i18n';
import 'stream-chat-react/dist/css/v2/index.css';
import { useInitialiseChatClient } from './useInitialiseChatClient';

export const StreamChatClient = ({
    collaborationPublicId,
    token,
}: {
    collaborationPublicId: string;
    token: string;
}) => {
    const { chatClient } = useInitialiseChatClient(token);

    if (!chatClient)
        return (
            <BoundedContent padding={'5'}>
                <Spinner accessibleText={__('Loading...')} />
            </BoundedContent>
        );

    const channel = chatClient.channel('messaging', collaborationPublicId);

    return (
        <Chat client={chatClient}>
            <Channel channel={channel}>
                <Window>
                    <ChannelHeader />
                    <MessageList />
                    <MessageInput />
                </Window>
                <Thread />
            </Channel>
        </Chat>
    );
};
