import { Helmet } from 'react-helmet-async';
import { MessagingPanel } from '@99designs/collaborate-views';
import { __ } from '@99designs/i18n';

export type MessagingRouteParams = {
    deliveryVersionId: string;
};

export const Messaging = () => {
    return (
        <>
            <Helmet>
                {/* TODO: Finalise page title and translate */}
                <title>Vistaprint | Design Services Messaging</title>
            </Helmet>
            <MessagingPanel />
        </>
    );
};
