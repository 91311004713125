/* eslint-disable */

import * as Apollo from '@apollo/client';
import { DocumentNode } from 'graphql';
import * as Types from '@99designs/graph-utils/types';

export type GetOrCreateChatMutationVariables = Types.Exact<{
    collaborationPublicId: Types.Scalars['String'];
}>;

export type GetOrCreateChatMutation = {
    __typename: 'Mutation';
    getOrCreateChat: { __typename: 'GetOrCreateChatResponse'; streamToken: string };
};

export const GetOrCreateChatDocument: DocumentNode = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'GetOrCreateChat' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'collaborationPublicId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getOrCreateChat' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'collaborationPublicId' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'collaborationPublicId' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                { kind: 'Field', name: { kind: 'Name', value: 'streamToken' } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
};
export type GetOrCreateChatMutationFn = Apollo.MutationFunction<
    GetOrCreateChatMutation,
    GetOrCreateChatMutationVariables
>;

/**
 * __useGetOrCreateChatMutation__
 *
 * To run a mutation, you first call `useGetOrCreateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateChatMutation, { data, loading, error }] = useGetOrCreateChatMutation({
 *   variables: {
 *      collaborationPublicId: // value for 'collaborationPublicId'
 *   },
 * });
 */
export function useGetOrCreateChatMutation(
    baseOptions?: Apollo.MutationHookOptions<
        GetOrCreateChatMutation,
        GetOrCreateChatMutationVariables
    >
) {
    return Apollo.useMutation<GetOrCreateChatMutation, GetOrCreateChatMutationVariables>(
        GetOrCreateChatDocument,
        baseOptions
    );
}
export type GetOrCreateChatMutationHookResult = ReturnType<typeof useGetOrCreateChatMutation>;
export type GetOrCreateChatMutationResult = Apollo.MutationResult<GetOrCreateChatMutation>;
export type GetOrCreateChatMutationOptions = Apollo.BaseMutationOptions<
    GetOrCreateChatMutation,
    GetOrCreateChatMutationVariables
>;
