import { useTracking } from '@vp/react-tracking';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    AlertBox,
    Box,
    Button,
    Divider,
    Dropdown,
    DropdownOption,
    FlexBox,
    H2,
    Icon,
    Link,
    Typography,
    responsive,
    tokens,
    tokensRaw,
    useScreenClass,
} from '@vp/swan';
import { bugtracker } from '@99designs/design-services-common';
import { Appointment } from '@99designs/graph-utils/types';
import { FormattedReactMessage, __, __vistaRoute, getCurrentCulture } from '@99designs/i18n';
import { TRACKING_PAGE_MY_ACCOUNT, useCollaborationContext } from '../../context';
import { DateSelector, DesignerCalendarSelector, TimeSelector } from '../../ui';
import { useUpdateAppointmentMutation } from './AppointmentPanel.generated';

interface AppointmentPanelProps {
    appointment: Appointment;
    deliveryVersionId: string;
}

const ResponsiveFlexBox = responsive(FlexBox);

export const AppointmentPanel = ({ appointment, deliveryVersionId }: AppointmentPanelProps) => {
    const { locale, collaborationId, trackingProperties, viewerRole, environmentForScheduler } =
        useCollaborationContext();
    const currentCulture = useMemo(() => getCurrentCulture(), []);
    const screenClass = useScreenClass();
    const [
        updateAppointment,
        { loading: updateAppointmentLoading, error: updateAppointmentError },
    ] = useUpdateAppointmentMutation();

    const navigate = useNavigate();
    const { tracking } = useTracking();

    const [time, setTime] = useState('');
    const [date, setDate] = useState('');
    const [timezone, setTimezone] = useState(appointment.timezone);
    const [calendarId, setCalendarId] = useState<number>(appointment.designerCalendarId);

    // returns the current appointment timezone and the browser timezone
    // we can update this later if we want a full list of timezones
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const availableTimezones = useMemo(() => {
        return [...new Set([appointment.timezone, browserTimezone])];
    }, [appointment.timezone, browserTimezone]);

    const rescheduleRefMobile = useRef<HTMLDivElement>(null);
    const rescheduleRefDesktop = useRef<HTMLDivElement>(null);

    // Scrolls to the bottom of the reschedule panel when the date/time is selected to bring TimeSelector into view
    useEffect(() => {
        const scrollToElement =
            screenClass === 'xs'
                ? rescheduleRefMobile.current?.parentElement // overflowing scroll element is the from the Drawer
                : rescheduleRefDesktop.current;

        if (scrollToElement) {
            scrollToElement.scroll({ top: scrollToElement.scrollHeight, behavior: 'smooth' });
        }
    }, [screenClass, date, time]);

    useEffect(() => {
        tracking.page(`Design Services:Collaboration:RescheduleSession`, {
            ...trackingProperties,
            pageName: `Design Services:Collaboration:RescheduleSession:${trackingProperties.collaborationStatus}`,
            pageSection: TRACKING_PAGE_MY_ACCOUNT,
            pageStage: TRACKING_PAGE_MY_ACCOUNT,
        });
    }, []);

    return (
        <FlexBox
            flexDirection="column"
            justifyContent="space-between"
            style={{ height: '100%' }}
            ref={rescheduleRefMobile}
        >
            {screenClass !== 'xs' && (
                <Box padding="5">
                    <Button
                        skin="tertiary"
                        aria-label={__('Back')}
                        onClick={() => navigate('../')}
                        buttonShape="round"
                    >
                        <Icon iconType="arrowLeft" aria-hidden="true" size="standard" />
                    </Button>
                </Box>
            )}
            <ResponsiveFlexBox
                sm={{ padding: 7, paddingBottom: 10 }}
                xs={{ padding: 5, paddingBottom: 11 }}
                flexDirection="column"
                gap="7"
                overflow="auto"
                style={{ height: '100%' }}
                ref={rescheduleRefDesktop}
            >
                {viewerRole === 'VIEWER_ROLE_DESIGNER' && (
                    <AlertBox skin="info">
                        <p>{__('You are viewing this appointment as a designer.')}</p>
                        <p>
                            {__('The DesignLive video call is currently booked for {{dateTime}}.', {
                                dateTime: humanTime(appointment.startTime, currentCulture, true),
                            })}
                        </p>
                        <p>
                            {__(
                                'You cannot reschedule this appointment on the customer’s behalf. Please contact the customer if you need to make changes.'
                            )}
                        </p>
                    </AlertBox>
                )}

                {!appointment.canBeRescheduled && (
                    <AlertBox toast skin="warning">
                        <p>{__('Sorry, this appointment can no longer be rescheduled.')}</p>
                        <p>
                            {__('Your DesignLive video call was booked for {{dateTime}}.', {
                                dateTime: humanTime(appointment.startTime, currentCulture, true),
                            })}
                        </p>
                        <p>
                            <FormattedReactMessage
                                message={__(
                                    'If you have any questions or would like to contact us, please visit our <Link>help centre</Link>.'
                                )}
                                replacements={{
                                    Link: (
                                        <Link
                                            to={__vistaRoute('/customer-care/help-center/')}
                                            target="_blank"
                                        />
                                    ),
                                }}
                            />
                        </p>
                    </AlertBox>
                )}

                {appointment.canBeRescheduled && (
                    <>
                        <H2 fontSkin="title-section">
                            {__('Reschedule your DesignLive video call')}
                        </H2>
                        <Typography fontSkin="body-standard">
                            {__(
                                'Your DesignLive video call is currently booked for {{dateTime}}. Select a new date and time below to reschedule your appointment.',
                                {
                                    dateTime: humanTime(
                                        appointment.startTime,
                                        currentCulture,
                                        true
                                    ),
                                }
                            )}
                        </Typography>
                        <ResponsiveFlexBox
                            xs={{ flexDirection: 'column', gap: 'between-sections' }}
                            sm={{
                                flexDirection: 'row',
                                gap: 'between-actions',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box style={{ flex: '1 1 100%' }}>
                                <Typography
                                    fontSkin="title-item"
                                    textColor="subtle"
                                    marginBottom="3"
                                >
                                    {__('Select a designer')}
                                </Typography>
                                <DesignerCalendarSelector
                                    designerCalendarId={calendarId || 0}
                                    setDesignerCalendarId={setCalendarId}
                                    initialDesignerName={appointment.designerName}
                                    initialDesignerCalendarId={appointment.designerCalendarId}
                                />
                            </Box>

                            <Box style={{ flex: '1 1 100%' }}>
                                <Typography
                                    fontSkin="title-item"
                                    textColor="subtle"
                                    marginBottom="3"
                                >
                                    {__('Your timezone')}
                                </Typography>

                                <Dropdown
                                    fullWidth
                                    value={timezone}
                                    onChange={(e) => setTimezone(e.target.value)}
                                    disabled={availableTimezones.length === 1}
                                    data-testid="timezone-dropdown"
                                >
                                    {availableTimezones.map((tz) => (
                                        <DropdownOption key={tz} value={tz}>
                                            {tz}
                                        </DropdownOption>
                                    ))}
                                </Dropdown>
                            </Box>
                        </ResponsiveFlexBox>

                        <FlexBox flexDirection="column" gap="3">
                            <Typography fontSkin="title-item" textColor="subtle">
                                {__('Select a date')}
                            </Typography>
                            <Box
                                style={{
                                    marginLeft: `-${tokensRaw.SwanSemSpace2}`,
                                    marginRight: `-${tokensRaw.SwanSemSpace2}`,
                                }} // a bit of a hack to offset inherent padding from DateSelector
                            >
                                <DateSelector
                                    timezone={timezone}
                                    date={date}
                                    locale={locale}
                                    setDate={setDate}
                                    calendarId={calendarId}
                                />
                            </Box>
                        </FlexBox>
                        {date && (
                            <FlexBox
                                flexDirection="column"
                                gap="3"
                                style={{ marginTop: `-${tokens.SwanSemSpace4}` }}
                            >
                                <Typography fontSkin="title-item" textColor="subtle">
                                    {__('Select a time')}
                                </Typography>
                                <TimeSelector
                                    time={time}
                                    setTime={setTime}
                                    date={date}
                                    locale={locale}
                                    timezone={timezone}
                                    calendarId={calendarId}
                                />
                            </FlexBox>
                        )}
                        {updateAppointmentError && (
                            <AlertBox toast skin="error">
                                {__(
                                    'There was an error scheduling the appointment. Please refresh the page to try again.'
                                )}
                            </AlertBox>
                        )}
                    </>
                )}
            </ResponsiveFlexBox>

            {appointment.canBeRescheduled && (
                <Box
                    backgroundColor="standard"
                    style={
                        screenClass === 'xs'
                            ? { position: 'fixed', bottom: 0, width: '100%', zIndex: 1000 }
                            : {}
                    }
                >
                    <Divider />
                    <FlexBox
                        justifyContent="flex-end"
                        alignItems="center"
                        gap="to-actions"
                        paddingX={screenClass === 'xs' ? '5' : '7'}
                        paddingY={screenClass === 'xs' ? '4' : '6'}
                    >
                        {time && (
                            <Box textAlign="right">
                                <Typography fontSkin="body-small">
                                    {__('This will be scheduled for:')}
                                </Typography>
                                <Typography fontSkin="body-standard-bold">
                                    {humanTime(time, locale)}
                                </Typography>
                            </Box>
                        )}
                        <Button
                            skin="primary"
                            onClick={async () => {
                                await updateAppointment({
                                    variables: {
                                        input: {
                                            collaborationPublicId: collaborationId,
                                            deliveryVersionId: parseInt(deliveryVersionId),
                                            appointmentTime: time,
                                            selectedTimezone: timezone,
                                            acuityCalendarId: calendarId,
                                        },
                                    },
                                    context: {
                                        headers: {
                                            Environment: environmentForScheduler,
                                        },
                                    },
                                    onError: (e) => {
                                        bugtracker.notify(e, (event) => {
                                            event.addMetadata(
                                                'debug',
                                                'collaborationId',
                                                collaborationId
                                            );
                                            event.addMetadata(
                                                'debug',
                                                'deliveryVersionId',
                                                deliveryVersionId
                                            );
                                            event.addMetadata('debug', 'appointmentTime', time);
                                            event.addMetadata(
                                                'debug',
                                                'selectedTimezone',
                                                timezone
                                            );
                                            event.addMetadata(
                                                'debug',
                                                'acuityCalendarId',
                                                calendarId
                                            );
                                        });
                                    },
                                });
                                tracking.track('DesignLive Rescheduler Confirmed', {
                                    ...trackingProperties,
                                    label: 'Appointment Time',
                                    time,
                                });
                                navigate('../activity', { relative: 'path' });
                            }}
                            loadingShimmer={updateAppointmentLoading}
                            disabled={
                                updateAppointmentLoading ||
                                !time ||
                                viewerRole === 'VIEWER_ROLE_DESIGNER'
                            }
                        >
                            {__('Confirm')}
                        </Button>
                    </FlexBox>
                </Box>
            )}
        </FlexBox>
    );
};

function humanTime(dateTime: string, locale: string, long?: boolean) {
    return new Date(dateTime)
        .toLocaleDateString(locale, {
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: long ? 'long' : undefined,
        })
        .replace(/^0/, '')
        .replace('a.m.', 'AM')
        .replace('p.m.', 'PM')
        .replace('am', 'AM')
        .replace('pm', 'PM');
}
