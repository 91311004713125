export enum Size {
    S = 64,
    M = 128,
    L = 320,
}

export function useAvatar(ninetyNineUserId: string | null | undefined, size: Size) {
    if (!ninetyNineUserId) return null;
    return `https://avatars.99static.com/avatars/${ninetyNineUserId}/${size}`;
}
