import { useEffect, useState } from 'react';
import Bugsnag from '@bugsnag/js';
import { __ } from '@99designs/i18n';
import { useGetOrCreateChatMutation } from './Chat.generated';

export const useFetchChatAndToken = (collaborationPublicId: string) => {
    const [token, setToken] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [createChatMutation] = useGetOrCreateChatMutation();

    useEffect(() => {
        const fetchChat = async () => {
            try {
                const { data } = await createChatMutation({
                    variables: { collaborationPublicId },
                });

                // TODO: Figure out how to handle token refresh when it expires
                const streamToken = data?.getOrCreateChat.streamToken;
                if (!streamToken) {
                    throw new Error('Failed to create chat with token.');
                }

                setToken(streamToken);
                setLoading(false);
            } catch (err) {
                setError(err as Error);
                setLoading(false);

                Bugsnag.notify(err, (event) => {
                    event.addMetadata('createChatMutation', { collaborationPublicId });
                });
            }
        };

        fetchChat();
    }, [createChatMutation, collaborationPublicId]);

    return { loading, error, token };
};
