import { Outlet, useLocation, useParams } from 'react-router-dom';
import { ResponsiveDrawerLayout } from '@99designs/collaborate-views';
import { __ } from '@99designs/i18n';
import { Canvas } from './routes/canvas/Canvas';

export const useLastSegment = (): string => {
    const location = useLocation();
    const segments = location.pathname.split('/');
    return segments[segments.length - 1];
};

export const useSubRoute = (): string | null => {
    const segment = useLastSegment();
    return ['activity', 'brief', 'revision', 'appointment', 'messaging'].includes(segment)
        ? segment
        : null;
};

export const FixedCanvas = () => {
    const { deliveryVersionId } = useParams<{ deliveryVersionId: string }>() as {
        deliveryVersionId: string;
    };

    const subRoute = useSubRoute();

    return (
        <ResponsiveDrawerLayout
            left={<Outlet />}
            right={
                <Canvas
                    subRoute={subRoute}
                    revisionRequestId={null}
                    deliveryId={deliveryVersionId}
                    interactable={false}
                />
            }
            hidePageOverlay={!subRoute || subRoute === 'revision'} // We do not want to render any overlay drawers for the delivery and revision route as the drawer actions are attached to the canvas
            scrollPositionAtBottom={subRoute === 'activity'}
        />
    );
};
